<template>
  <div class="block" style="height: 100vh">
    <router-view
      class="w-full center justify-center bg-cream"
      style="height: 100vh"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {},
  created() {},
  methods: {}
}
</script>
